import { ButtonHTMLAttributes, MouseEvent, forwardRef } from 'react'
import { DataTreemap24Regular } from '@fluentui/react-icons'
import { T } from '@transifex/react'
import { t } from '@transifex/native'
import { getNewPortalDashboardName, getNewPortalDashboardURL } from '../../../../selectors/settingsSelectors'
import { useAppDispatch } from '../../../hooks/useAppDispatch'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { NavigationSection, NavigationSectionHeaderButton } from '../primitives/NavigationSection'
import { NavigationSectionTooltip } from './NavigationSectionTooltip'

export const DashboardsNavigationSection = forwardRef<
  HTMLButtonElement,
  ({ isExpanded?: boolean } & ButtonHTMLAttributes<HTMLButtonElement>) | Record<string, never>
>(({ isExpanded, ...props }, ref) => {
  const dispatch = useAppDispatch()

  const dashboardName = useAppSelector(getNewPortalDashboardName)
  const dashboardURL = useAppSelector(getNewPortalDashboardURL)

  const handleDashboardClick = (event: MouseEvent<HTMLButtonElement>) => {
    dispatch({
      type: 'TOGGLE_DASHBOARD_MODAL',
      // this component is only mounted if !!dashboardURL
      payload: { url: dashboardURL!, name: dashboardName ?? null, isOpen: true },
    })

    if (typeof props.onClick === 'function') {
      props.onClick(event)
    }
  }

  return (
    <NavigationSection>
      <NavigationSectionTooltip tooltipString={t('Dashboard')} isExpanded={isExpanded}>
        <NavigationSectionHeaderButton {...props} ref={ref} onClick={handleDashboardClick} className="[&>*]:w-7">
          <DataTreemap24Regular className="shrink-0 text-sky.4" />
          {isExpanded && <T _str="Dashboard" />}
        </NavigationSectionHeaderButton>
      </NavigationSectionTooltip>
    </NavigationSection>
  )
})
DashboardsNavigationSection.displayName = 'DashboardsNavigationSection'
