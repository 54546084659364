import { Mail24Regular } from '@fluentui/react-icons'
import { T } from '@transifex/react'
import { ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import { t } from '@transifex/native'

import { NavigationComponentProps, NavigationSectionKey } from '../../../../../helpers/navigation'
import { NavigationSection, NavigationSectionHeaderLink } from '../../primitives/NavigationSection'
import { NavigationSectionTooltip } from '../NavigationSectionTooltip'
import { AlertsNavigationList } from './AlertsNavigationList'

export const AlertsNavigationSection = forwardRef<ElementRef<typeof NavLink>, NavigationComponentProps>(
  ({ isExpanded, ...props }, ref) => {
    return (
      <NavigationSection>
        <NavigationSectionTooltip tooltipString={t('Alerts')} isExpanded={isExpanded}>
          <NavigationSectionHeaderLink
            ref={ref}
            to="/alerts"
            className="[&>*]:w-7"
            section={NavigationSectionKey.ALERTS}
            {...props}
          >
            <Mail24Regular className="shrink-0 text-berry.4" />
            {isExpanded && <T _str="Alerts" />}
          </NavigationSectionHeaderLink>
        </NavigationSectionTooltip>

        <AlertsNavigationList />
      </NavigationSection>
    )
  },
)

AlertsNavigationSection.displayName = 'AlertsNavigationSection'
