import { Rss24Regular } from '@fluentui/react-icons'
import { T } from '@transifex/react'
import { t } from '@transifex/native'
import { ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { NavigationComponentProps, NavigationSectionKey } from '../../../../../helpers/navigation'
import { getFirstFeed } from '../../../../../selectors/feedsSelector'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useDefaultHomeUrl } from '../../../../hooks/useDefaultHomeUrl'
import { NavigationSection, NavigationSectionHeaderLink } from '../../primitives/NavigationSection'
import { NavigationSectionTooltip } from '../NavigationSectionTooltip'

import { FeedsNavigationList } from './FeedsNavigationList'

export const FeedsNavigationSection = forwardRef<ElementRef<typeof NavLink>, NavigationComponentProps>(
  ({ isExpanded, ...props }, ref) => {
    const firstFeed = useAppSelector(getFirstFeed)
    const defaultHomeUrl = useDefaultHomeUrl()

    return (
      <NavigationSection>
        <NavigationSectionTooltip tooltipString={t('Feeds')} isExpanded={isExpanded}>
          <NavigationSectionHeaderLink
            ref={ref}
            to={firstFeed?.id ? { pathname: `/feeds/${firstFeed.id}` } : defaultHomeUrl}
            section={NavigationSectionKey.FEEDS}
            className="[&>*]:w-7"
            {...props}
          >
            <Rss24Regular className="shrink-0 text-spring.1" />
            {isExpanded && <T _str="Feeds" />}
          </NavigationSectionHeaderLink>
        </NavigationSectionTooltip>

        <FeedsNavigationList />
      </NavigationSection>
    )
  },
)
FeedsNavigationSection.displayName = 'FeedsNavigationSection'
