import { DataTrending24Regular } from '@fluentui/react-icons'
import { T } from '@transifex/react'
import { ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import { t } from '@transifex/native'

import { NavigationComponentProps, NavigationSectionKey } from '../../../../../helpers/navigation'
import { getFirstStatistics } from '../../../../../selectors/statisticsSelectors'
import { useAppSelector } from '../../../../hooks/useAppSelector'
import { useDefaultHomeUrl } from '../../../../hooks/useDefaultHomeUrl'
import { NavigationSection, NavigationSectionHeaderLink } from '../../primitives/NavigationSection'
import { NavigationSectionTooltip } from '../NavigationSectionTooltip'
import { SavedStatisticsNavigationList } from './SavedStatisticsNavigationList'

export const SavedStatisticsNavigationSection = forwardRef<ElementRef<typeof NavLink>, NavigationComponentProps>(
  ({ isExpanded, ...props }, ref) => {
    const firstStatistics = useAppSelector(getFirstStatistics)
    const defaultHomeUrl = useDefaultHomeUrl()

    return (
      <NavigationSection>
        <NavigationSectionTooltip tooltipString={t('Saved statistics')} isExpanded={isExpanded}>
          <NavigationSectionHeaderLink
            ref={ref}
            to={
              firstStatistics?.id
                ? {
                    pathname: `/statistics/${firstStatistics.id}`,
                  }
                : defaultHomeUrl
            }
            section={NavigationSectionKey.SAVED_STATISTICS}
            className="[&>*]:w-7"
            {...props}
          >
            <DataTrending24Regular className="shrink-0 text-sky.4" />
            {isExpanded && <T _str="Saved statistics" />}
          </NavigationSectionHeaderLink>
        </NavigationSectionTooltip>

        <SavedStatisticsNavigationList />
      </NavigationSection>
    )
  },
)
SavedStatisticsNavigationSection.displayName = 'SavedStatisticsNavigationSection'
