import { AppsList24Regular } from '@fluentui/react-icons'
import { T } from '@transifex/react'
import { t } from '@transifex/native'
import { ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { NavigationComponentProps, NavigationSectionKey } from '../../../../helpers/navigation'
import { NavigationSection, NavigationSectionHeaderLink } from '../primitives/NavigationSection'
import { NavigationSectionTooltip } from './NavigationSectionTooltip'

export const FolderManagementNavigationSection = forwardRef<ElementRef<typeof NavLink>, NavigationComponentProps>(
  ({ isExpanded, ...props }, ref) => (
    <NavigationSection>
      <NavigationSectionTooltip tooltipString={t('Folder management')} isExpanded={isExpanded}>
        <NavigationSectionHeaderLink
          ref={ref}
          to="/folders"
          section={NavigationSectionKey.FOLDER_MANAGEMENT}
          className="[&>*]:w-7"
          {...props}
        >
          <AppsList24Regular className="shrink-0 text-spring.1" />
          {isExpanded && <T _str="Folder management" />}
        </NavigationSectionHeaderLink>
      </NavigationSectionTooltip>
    </NavigationSection>
  ),
)
FolderManagementNavigationSection.displayName = 'FolderManagementNavigationSection'
