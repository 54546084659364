import { Delete24Regular } from '@fluentui/react-icons'
import { T } from '@transifex/react'
import { t } from '@transifex/native'
import { ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { NavigationComponentProps, NavigationSectionKey } from '../../../../helpers/navigation'
import { getGlobalTrashTag } from '../../../../selectors/trashTagsSelectors'
import { useAppSelector } from '../../../hooks/useAppSelector'
import { useDefaultHomeUrl } from '../../../hooks/useDefaultHomeUrl'
import { SearchFilterKey } from '../../../hooks/useSearchFilters'
import useSearchRouteBuilder from '../../../hooks/useSearchRouteBuilder'
import { NavigationSection, NavigationSectionHeaderLink } from '../primitives/NavigationSection'
import { NavigationSectionTooltip } from './NavigationSectionTooltip'

export const TrashNavigationSection = forwardRef<ElementRef<typeof NavLink>, NavigationComponentProps>(
  ({ isExpanded, ...props }, ref) => {
    const globalTrashTag = useAppSelector(getGlobalTrashTag)
    const defaultHomeUrl = useDefaultHomeUrl()
    const { getSearchRoute } = useSearchRouteBuilder()

    return (
      <NavigationSection>
        <NavigationSectionTooltip tooltipString={t('Trash')} isExpanded={isExpanded}>
          <NavigationSectionHeaderLink
            ref={ref}
            to={globalTrashTag?.id ? getSearchRoute(SearchFilterKey.TRASH, globalTrashTag.id) : defaultHomeUrl}
            section={NavigationSectionKey.TRASH}
            className="[&>*]:w-7"
            {...props}
          >
            <Delete24Regular className="shrink-0 text-berry.4" />
            {isExpanded && <T _str="Trash" />}
          </NavigationSectionHeaderLink>
        </NavigationSectionTooltip>
      </NavigationSection>
    )
  },
)
TrashNavigationSection.displayName = 'TrashNavigationSection'
