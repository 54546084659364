import { Reducer } from 'redux'
import { getArticleId } from '@opoint/infomedia-storybook'
import {
  __,
  always,
  assoc,
  assocPath,
  clamp,
  compose,
  concat,
  contains,
  dec,
  dissoc,
  evolve,
  filter,
  identity,
  inc,
  indexBy,
  map,
  modulo,
  prop,
  reduce,
  uniqBy,
  when,
} from 'ramda'
import { AppActions } from '../actions'
import { ArticleMatches, IdenticalDocument } from '../components/types/article'
import * as Actions from '../constants/actionTypes'
import { reduceDuplicateSnippetOccurrence } from '../helpers/common'
import { eqArticles, identicalReducer, preprocessArticles } from '../opoint/articles'
import { OpointTimestampToTimestamp } from '../opoint/common/time'
import type { SetActiveArticle, Source, XhrStatus } from '../opoint/flow'
import { TAG_TYPES } from '../opoint/tags'
import { M360Article } from '../opoint/articles/types'
import { DocumentIdenticalDocumentsDocumentItem } from '../api/opoint-search-suggest.schemas'

const getAffectedArticles = (mapFunction, articles: M360Article[], articlesList: M360Article[]) =>
  compose(
    // index by id
    indexBy(prop('id')<string>),
    map(mapFunction),
    // get affected articles based on payload request
    filter((f: M360Article) =>
      contains(
        getArticleId(f),
        articles.map((a) => getArticleId(a)),
      ),
    ),
  )(articlesList)

export type ArticlesStateType = {
  list: Array<M360Article>
  identical: Record<string, number>
  checked: string[]
  active: SetActiveArticle
  addArticleForm: {
    isSaving?: boolean
  }
  editedArticle: M360Article
  editedArticleXHRStatus: XhrStatus
  shareArticleXHRStatus: XhrStatus
  shareArticleMessage: string
  shareArticleAttachmentFlag: boolean
  editedArticleState: Array<string>
  updatedArticle: M360Article
  deleteModalOpen: boolean
  checkAllFiltered: boolean
}

export const initialState: ArticlesStateType = {
  list: [],
  identical: {},
  checked: [],
  active: {
    // @ts-expect-error: Muted so we could enable TS strict mode
    index: null,
    // @ts-expect-error: Muted so we could enable TS strict mode
    source: null,
  },
  addArticleForm: {},
  // @ts-expect-error: Muted so we could enable TS strict mode
  editedArticle: null,
  editedArticleXHRStatus: 'NOT_INITIATED',
  shareArticleXHRStatus: 'NOT_INITIATED',
  shareArticleMessage: '',
  shareArticleAttachmentFlag: true,
  editedArticleState: [],
  // @ts-expect-error: Muted so we could enable TS strict mode
  updatedArticle: null,
  deleteModalOpen: false,
  checkAllFiltered: false,
}

const editedArticlesArr = []

const articlesReducer: Reducer<ArticlesStateType, AppActions> = (
  state: ArticlesStateType = initialState,
  action,
): ArticlesStateType => {
  switch (action.type) {
    case 'LOGOUT':
      return initialState

    case 'FETCH_ARTICLES':
      return { ...state, list: [], checked: [] }

    case Actions.FETCH_ARTICLES_SUCCESS: {
      // TODO - this is the same as here PROFILE_EDITOR_PREVIEW_SUCCESS
      // we should factor this out.

      // @ts-expect-error: Muted so we could enable TS strict mode
      const interceptedDocuments: M360Article[] = preprocessArticles(action.payload.response.searchresult.document)

      // TODO: Investigate why are articles processed differently in different parts of the application
      // https://infomediacorp.atlassian.net/browse/FE-10066

      interceptedDocuments?.forEach((article: M360Article) => {
        article.matches = reduceDuplicateSnippetOccurrence(article.matches || []) as unknown as ArticleMatches
      })

      return evolve({
        /* eslint-disable-next-line no-underscore-dangle */
        identical: reduce(identicalReducer, __, action.payload.response.searchresult.document),
        list: always(interceptedDocuments),
      })(state)
    }

    case 'ALERT_FETCH_CONTENT_SUCCESS': {
      const { data } = action.payload

      // Sort identical articles by date in descending order
      // Only if data has JSON content
      if (Array.isArray(data.content)) {
        let documents = []
        data.content.forEach((profile) => {
          if (profile?.content?.searchresult?.document) {
            // @ts-expect-error: Muted so we could enable TS strict mode
            documents = [...documents, ...profile.content.searchresult.document]
          }
        })

        documents = documents.filter(({ identical_documents }) => identical_documents)

        return evolve({
          /* eslint-disable-next-line no-underscore-dangle */
          identical: reduce(identicalReducer, __, documents),
        })(state)
      }

      return state
    }

    case Actions.FETCH_SINGLE_ARTICLE_FOR_TRANSLATION_SUCCESS: {
      const { response, translate, isIdentical, originalArticle } = action.payload
      const preprocessedArticle = preprocessArticles(response.searchresult.document)[0]

      if (isIdentical) {
        const {
          // @ts-expect-error: Muted so we could enable TS strict mode
          identical_documents: { document },
          identical_documents,
        } = originalArticle

        const newIdenticalDocument = document?.map((item) => {
          if (getArticleId(item) === getArticleId(preprocessedArticle)) {
            return { ...preprocessedArticle, translated: translate }
          }

          return item
        })

        // @ts-expect-error: Muted so we could enable TS strict mode
        return evolve({
          list: always(
            state.list?.map((item) => {
              if (getArticleId(item) === getArticleId(originalArticle)) {
                return {
                  ...originalArticle,
                  identical_documents: { ...identical_documents, document: newIdenticalDocument },
                }
              }

              return item
            }),
          ),
        })(state)
      }

      // @ts-expect-error: Muted so we could enable TS strict mode
      return evolve({
        list: always(
          state.list?.map((item) => {
            if (getArticleId(item) === getArticleId(preprocessedArticle)) {
              return { ...preprocessedArticle, translated: translate }
            }

            return item
          }),
        ),
      })(state)
    }

    case Actions.FETCH_SINGLE_ARTICLE_FOR_ARTICLE_VIEW_SUCCESS: {
      const { response } = action.payload
      const preprocessedArticle = preprocessArticles(response.searchresult.document)[0]

      // @ts-expect-error: Muted so we could enable TS strict mode
      return evolve({
        list: always(preprocessedArticle ? [preprocessedArticle] : []),
      })(state)
    }

    case Actions.CLEAR_ARTICLES:
      // @ts-expect-error: Muted so we could enable TS strict mode
      return evolve({
        list: always([]),
        identical: always({}),
        checked: always([]),
        checkAllFiltered: always(false),
        active: always({
          index: null,
          source: null,
        }),
      })(state)

    case 'FETCH_ARTICLES_WITH_WATCH_ID_SUCCESS': {
      // @ts-expect-error: Muted so we could enable TS strict mode
      const interceptedDocuments: M360Article[] = preprocessArticles(action.payload.searchresult.document)

      interceptedDocuments?.forEach((article: M360Article) => {
        article.matches = reduceDuplicateSnippetOccurrence(article.matches || []) as unknown as ArticleMatches
      })

      // @ts-expect-error: Muted so we could enable TS strict mode
      return evolve({
        identical: reduce(identicalReducer, __, action.payload.searchresult.document),
        list: compose(uniqBy(getArticleId), concat(interceptedDocuments)),
      })(state)
    }

    case Actions.FETCH_MORE_ARTICLES_SUCCESS: {
      const interceptedDocuments = preprocessArticles(action.payload.response.searchresult.document)

      // @ts-expect-error: Muted so we could enable TS strict mode
      interceptedDocuments?.forEach((article: M360Article) => {
        article.matches = reduceDuplicateSnippetOccurrence(article.matches || []) as unknown as ArticleMatches
      })

      // @ts-expect-error: Muted so we could enable TS strict mode
      return evolve({
        /* eslint-disable-next-line no-underscore-dangle */
        identical: reduce(identicalReducer, __, action.payload.response.searchresult.document),
        list: compose(
          uniqBy(getArticleId),
          /* eslint-disable-next-line no-underscore-dangle */
          concat(__, interceptedDocuments),
        ),
      })(state)
    }

    case Actions.CHECK_ALL_FILTERED_ARTICLES: {
      const filteredArticles = action.payload

      const checkedArticles = filteredArticles.map((article) => getArticleId(article))
      return {
        ...state,
        checked: checkedArticles,
        checkAllFiltered: true,
      }
    }

    case Actions.CHECK_ARTICLE_TOGGLE: {
      const id = getArticleId(action.payload)
      const isArticleChecked = state.checked.some((articleId) => articleId === id)

      if (isArticleChecked) {
        return {
          ...state,
          checked: state.checked.filter((articleId) => articleId !== id),
        }
      }

      return {
        ...state,
        checked: [...state.checked, getArticleId(action.payload)],
      }
    }

    case Actions.UNCHECK_ALL_ARTICLES:
      return {
        ...state,
        checked: [],
        checkAllFiltered: false,
      }

    case Actions.NEXT_IDENTICAL: {
      const identicalArticles = action.payload?.article?.identical_documents?.document

      if (!identicalArticles) {
        return state
      }

      return evolve(
        {
          identical: {
            [getArticleId(action.payload.article)]: compose(
              /* eslint-disable-next-line no-underscore-dangle */
              modulo(__, identicalArticles.length),
              inc,
            ),
          },
        },
        state,
      )
    }

    case Actions.PREVIOUS_IDENTICAL: {
      const identicalArticles = action.payload?.article?.identical_documents?.document

      if (!identicalArticles) {
        return state
      }

      const currentItem = getArticleId(action.payload.article)

      return {
        ...state,
        identical: {
          ...state.identical,
          [getArticleId(action.payload.article)]:
            (state.identical[currentItem] + identicalArticles.length - 1) % identicalArticles.length,
        },
      }
    }

    case Actions.SET_ACTIVE_IDENTICAL: {
      const { article, index } = action.payload

      return evolve(
        {
          identical: {
            [getArticleId(article)]: always(index),
          },
        },
        state,
      )
    }

    case Actions.NEXT_ACTIVE_ARTICLE:
      return evolve({
        active: {
          index: compose(clamp(0, state.list.length), inc),
          source: always('keyPress' as Source),
        },
      })(state)

    case Actions.PREVIOUS_ACTIVE_ARTICLE:
      return evolve({
        active: {
          index: compose(clamp(0, state.list.length), dec),
          source: always('keyPress' as Source),
        },
      })(state)

    case Actions.SET_ACTIVE_ARTICLE: {
      const { index, source } = action.payload

      return { ...state, active: { index: Math.min(Math.max(0, index), state.list.length), source } }
    }

    case 'CATEGORIZATION_TAG_ARTICLE':
    case 'TAG_ARTICLES': {
      const {
        articles,
        tag,
        weight = tag?.type === TAG_TYPES.MENTOMETER ? 0 : 1,
        toTagOnlyMainArticle,
      } = action.payload || {}
      const articleArray = Array.isArray(articles) ? articles : [articles]

      const editedArticleId = state.editedArticle?.id
      const isItEditableArticle = articles.find(({ id }) => id === editedArticleId)

      const synthTag = {
        id: tag.id,
        is_owner: 1,
        weight,
        set: OpointTimestampToTimestamp(),
      }

      const setJustForMainArticle = (article: M360Article) => assocPath(['tags', tag.id], synthTag)(article)

      const setTag = evolve({
        tags: assoc(`${tag.id}`, synthTag),
        identical_documents: (identicalDocuments) => {
          if (identicalDocuments.count === 0) {
            return identicalDocuments
          }
          const { cnt, document: identicalArticles } = identicalDocuments
          const updatedIdenticals = identicalArticles?.map(setTag)

          return { cnt, document: updatedIdenticals }
        },
      })

      const updateList = (articlesList) => {
        const affectedArticles = getAffectedArticles(
          toTagOnlyMainArticle ? setJustForMainArticle : setTag,
          articleArray,
          articlesList,
        )

        return articlesList.reduce((a, v) => a.concat(affectedArticles[v.id] ? affectedArticles[v.id] : v), [])
      }

      return evolve(
        {
          list: updateList,
          editedArticle: isItEditableArticle ? setTag : identity,
        },
        state,
      )
    }

    case 'CATEGORIZATION_UNTAG_ARTICLE':
    case 'UNTAG_ARTICLES': {
      /* eslint-disable-next-line  prefer-const */
      let { articles, tagId } = action.payload

      if (!Array.isArray(articles)) {
        articles = [articles]
      }

      const editedArticleId = state.editedArticle?.id
      const isItEditableArticle = articles.find(({ id }) => id === editedArticleId)

      const unsetTag = evolve({
        tags: dissoc(`${tagId}`),
        identical_documents: (identicalDocuments: IdenticalDocument) => {
          if (identicalDocuments.cnt === 0) {
            return identicalDocuments
          }
          const { cnt, document: identicalArticles } = identicalDocuments

          const updatedIdenticals = identicalArticles?.map((article: DocumentIdenticalDocumentsDocumentItem) =>
            unsetTag(article),
          )

          return { cnt, document: updatedIdenticals }
        },
      })

      const updateList = (articlesList: M360Article[]) => {
        const affectedArticles = getAffectedArticles(unsetTag, articles as M360Article[], articlesList)

        // replace the newly tagged articles
        // @ts-expect-error: Muted so we could enable TS strict mode
        return articlesList.reduce((a, v) => a.concat(affectedArticles[v.id] ? affectedArticles[v.id] : v), [])
      }

      return evolve(
        {
          list: updateList,
          editedArticle: isItEditableArticle ? unsetTag : identity,
        },
        state,
      )
    }

    case 'TAG_SINGLE_ARTICLE': {
      const { article, tag, originalArticle, weight = tag.type === TAG_TYPES.MENTOMETER ? 0 : 1 } = action.payload || {}

      const synthTag = {
        id: tag.id,
        is_owner: 1,
        weight,
        set: OpointTimestampToTimestamp(),
      }

      const updateList = (articlesList) => {
        const affectedArticles = {
          // @ts-expect-error: Muted so we could enable TS strict mode
          [originalArticle.id]: (() => {
            const identicalDocuments = originalArticle.identical_documents
            // @ts-expect-error: Muted so we could enable TS strict mode
            if (identicalDocuments.cnt === 0) {
              return originalArticle
            }

            // @ts-expect-error: Muted so we could enable TS strict mode
            const updatedIdenticals = identicalDocuments.document.map((identArticle) => {
              if (identArticle.id_article === article.id_article) {
                const updatedTags = {
                  ...identArticle.tags,
                  [tag.id]: synthTag,
                }

                return { ...identArticle, tags: updatedTags }
              }

              return identArticle
            })

            return {
              ...originalArticle,
              identical_documents: {
                ...identicalDocuments,
                document: updatedIdenticals,
              },
            }
          })(),
        }

        // Replace the newly tagged articles
        return articlesList.reduce((acc, currentArticle) => {
          const updatedArticle = affectedArticles[currentArticle.id]
          if (updatedArticle) {
            return [...acc, updatedArticle]
          }

          return [...acc, currentArticle]
        }, [])
      }

      return {
        ...state,
        list: updateList(state.list),
      }
    }

    case 'UNTAG_SINGLE_ARTICLE': {
      const { article, tag, originalArticle } = action.payload

      const updateList = (articlesList) => {
        const affectedArticles = articlesList.map((listArticle) => {
          if (listArticle.id === originalArticle.id) {
            if (originalArticle.identical_documents?.cnt === 0) {
              return originalArticle
            }

            // @ts-expect-error: Muted so we could enable TS strict mode
            const updatedIdenticals = originalArticle.identical_documents.document.map((identArticle) => {
              if (identArticle.id_article === article.id_article) {
                const updatedTags = { ...identArticle.tags }
                delete updatedTags[tag.id]

                return { ...identArticle, tags: updatedTags }
              }

              return identArticle
            })

            return {
              ...originalArticle,
              identical_documents: {
                ...originalArticle.identical_documents,
                document: updatedIdenticals,
              },
            }
          }

          return listArticle
        })

        return affectedArticles
      }

      return {
        ...state,
        list: updateList(state.list),
      }
    }

    case Actions.DELETE_ARTICLES: {
      /* eslint-disable-next-line  prefer-const */
      let { articles, tag } = action.payload || {}

      if (!Array.isArray(articles)) {
        articles = [articles]
      }

      const synthTag = {
        id: tag?.id,
        is_owner: 1,
        set: OpointTimestampToTimestamp(),
      }

      const updateList = (articlesList) => {
        const af = getAffectedArticles(
          (article) => assocPath(['tags', tag?.id], synthTag)(article),
          articles as M360Article[],
          articlesList,
        )

        // replace the newly tagged articles
        return articlesList.reduce((a, v) => a.concat(af[v.id] ? af[v.id] : v), [])
      }

      return evolve(
        {
          list: updateList,
        },
        state,
      )
    }

    case Actions.UNDELETE_ARTICLES: {
      const { tag } = action.payload
      let { articles } = action.payload

      if (!Array.isArray(articles)) {
        articles = [articles]
      }

      const updateList = (articlesList) => {
        const af = getAffectedArticles(
          (article) => {
            const newArticle = { ...article }
            newArticle.tags[tag.id] = undefined

            return newArticle
          },
          articles as M360Article[],
          articlesList,
        )

        // replace the newly tagged articles
        return articlesList.reduce((a, v) => [...a, af[v.id] ? af[v.id] : v], [])
      }

      return {
        ...state,
        list: updateList(state.list),
      }
    }

    case 'ADD_ARTICLE_MODAL_CLOSE':
      return assoc('addArticleForm', {}, state)

    case Actions.ADD_ARTICLE_FILE_UPLOAD_SUCCESS: {
      return assocPath(['addArticleForm', 'isSaving'], false, state)
    }

    case Actions.ADD_ARTICLE:
      return assocPath(['addArticleForm', 'isSaving'], true, state)

    case Actions.ADD_ARTICLE_FAILURE:
      return assocPath(['addArticleForm', 'isSaving'], false, state)

    case Actions.ADD_ARTICLE_SUCCESS:
      return {
        ...state,
        addArticleForm: {
          isSaving: false,
        },
      }

    case 'EDIT_ARTICLE_MODAL_OPEN': {
      const { article } = action.payload

      return {
        ...state,
        editedArticle: article,
      }
    }

    case Actions.UPDATED_ARTICLE: {
      return assoc('updatedArticle', action.payload)(state)
    }

    case Actions.EDIT_ARTICLE: {
      return assoc('editedArticleXHRStatus', 'IN_PROGRESS' as XhrStatus, state)
    }

    case Actions.EDIT_ARTICLE_FAILURE: {
      return assoc('editedArticleXHRStatus', 'NOT_INITIATED' as XhrStatus, state)
    }
    case Actions.EDIT_ARTICLE_SUCCESS: {
      const { article } = action.payload

      // @ts-expect-error: Muted so we could enable TS strict mode
      return evolve(
        {
          editedArticleXHRStatus: always('NOT_INITIATED' as XhrStatus),
          list: map(when(eqArticles(article), always(article))),
          editedArticle: always(article),
        },
        state,
      )
    }
    case Actions.EDIT_ECB_ARTICLE_SUCCESS: {
      const { article } = action.payload

      // @ts-expect-error: Muted so we could enable TS strict mode
      return evolve(
        {
          list: map(when(eqArticles(article), always(article))),
        },
        state,
      )
    }

    case 'SHARE_ARTICLE_MODAL_CLOSE': {
      return evolve({
        shareArticleXHRStatus: always('NOT_INITIATED' as XhrStatus),
        shareArticleMessage: always(''),
        shareArticleAttachmentFlag: always(false),
      })(state)
    }

    case Actions.SHARE_ARTICLES: {
      return assoc('shareArticleXHRStatus', 'IN_PROGRESS' as XhrStatus, state)
    }

    case Actions.SHARE_ARTICLES_SUCCESS: {
      return assoc('shareArticleXHRStatus', 'SUCCESS' as XhrStatus, state)
    }

    case Actions.SHARE_ARTICLES_FAILURE: {
      return assoc('shareArticleXHRStatus', 'FAILURE' as XhrStatus, state)
    }

    case Actions.SHARE_ARTICLES_TOGGLE_ATTACHMENT_TOGGLE: {
      return assoc('shareArticleAttachmentFlag', !state.shareArticleAttachmentFlag, state)
    }

    case Actions.SHARE_ARTICLES_CHANGE_MESSAGE: {
      const { message } = action.payload

      return assoc('shareArticleMessage', message, state)
    }

    case Actions.EDIT_ARTICLES_STATE: {
      // @ts-expect-error: Muted so we could enable TS strict mode
      editedArticlesArr.push(action.payload)

      return assoc('editedArticleState', editedArticlesArr, state)
    }

    case Actions.SET_DELETE_ARTICLE_MODAL: {
      return { ...state, deleteModalOpen: action.payload }
    }

    case Actions.SEARCH_CANCELED: {
      return {
        ...state,
        list: [],
      }
    }

    default:
      return state
  }
}

export default articlesReducer
