import { PeopleSettings24Regular } from '@fluentui/react-icons'
import { T } from '@transifex/react'
import { ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'
import { t } from '@transifex/native'

import { NavigationComponentProps, NavigationSectionKey } from '../../../../helpers/navigation'
import { NavigationSection, NavigationSectionHeaderLink } from '../primitives/NavigationSection'
import { NavigationSectionTooltip } from './NavigationSectionTooltip'

export const UserManagementNavigationSection = forwardRef<ElementRef<typeof NavLink>, NavigationComponentProps>(
  ({ isExpanded, ...props }, ref) => (
    <NavigationSection>
      <NavigationSectionTooltip tooltipString={t('User management')} isExpanded={isExpanded}>
        <NavigationSectionHeaderLink
          ref={ref}
          to="/user-management/all"
          section={NavigationSectionKey.USER_MANAGEMENT}
          className="[&>*]:w-7"
          {...props}
        >
          <PeopleSettings24Regular className="shrink-0 text-sea.2" />
          {isExpanded && <T _str="User management" />}
        </NavigationSectionHeaderLink>
      </NavigationSectionTooltip>
    </NavigationSection>
  ),
)
UserManagementNavigationSection.displayName = 'UserManagementNavigationSection'
