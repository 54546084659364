import { Eye24Regular } from '@fluentui/react-icons'
import { T } from '@transifex/react'
import { t } from '@transifex/native'
import { ElementRef, forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { NavigationComponentProps, NavigationSectionKey } from '../../../../helpers/navigation'
import { NavigationSection, NavigationSectionHeaderLink } from '../primitives/NavigationSection'
import { NavigationSectionTooltip } from './NavigationSectionTooltip'

export const CustomerViewNavigationSection = forwardRef<ElementRef<typeof NavLink>, NavigationComponentProps>(
  ({ isExpanded, ...props }, ref) => (
    <NavigationSection>
      <NavigationSectionTooltip tooltipString={t('Customer view')} isExpanded={isExpanded}>
        <NavigationSectionHeaderLink
          ref={ref}
          to="/customer_view"
          section={NavigationSectionKey.CUSTOMER_VIEW}
          className="[&>*]:w-7"
          {...props}
        >
          <Eye24Regular className="shrink-0 text-sea.2" />
          {isExpanded && <T _str="Customer view" />}
        </NavigationSectionHeaderLink>
      </NavigationSectionTooltip>
    </NavigationSection>
  ),
)
CustomerViewNavigationSection.displayName = 'CustomerViewNavigationSection'
