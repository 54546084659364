import { Tooltip, TooltipContent, TooltipPortal, TooltipProvider, TooltipTrigger } from '@opoint/infomedia-storybook'
import { T } from '@transifex/react'
import { ReactElement } from 'react'

export const NavigationSectionTooltip = ({
  children,
  isExpanded,
  tooltipString,
}: {
  children: ReactElement
  isExpanded?: boolean
  tooltipString: string
}) => {
  return (
    <TooltipProvider>
      {/* Block tooltip when menu is expanded, assume it is expanded if variable doesn't exist */}
      <Tooltip open={isExpanded === true || undefined ? false : undefined}>
        <TooltipTrigger className="h-full w-full">{children}</TooltipTrigger>
        <TooltipPortal>
          <TooltipContent side="right">
            <T _str={tooltipString} />
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    </TooltipProvider>
  )
}
